<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <!-- <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入banner名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList(true)">搜索</el-button> -->
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div>

    <el-table border :data="tableData" height="76vh" style="width: 99%" @sort-change="changeSort">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="productName" label="名称"></el-table-column>
      <el-table-column prop="productUrl" label="图标">
        <template slot-scope="scope">
          <img :width="80" :src="scope.row.productUrl" />
        </template>
      </el-table-column>
      <el-table-column prop="goldCoinsNum" label="金币数量">
        <template slot-scope="scope"> 
          <span>{{scope.row.payType===4?scope.row.goldCoinsNum/10000+'ETH':scope.row.goldCoinsNum+'Talk'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="productPrice" label="商品价格">
        <template slot-scope="scope"> 
          <div v-if="scope.row.payType===3">
            <p v-for="item in scope.row.payChannelPriceList" :key="item.id" class="priceItem">
              <!-- <img :src="item.channelIcon" width="36"> -->
              {{item.channelName}}
              {{item.currency}}
              {{item.price}}
            </p>
          </div>
          <span v-else>{{scope.row.payType===4?'':'$'+scope.row.productPrice}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="productId" label="服务商商品id"></el-table-column>
      <el-table-column prop="payType" label="支付类型">
        <template slot-scope="scope">
          <span>{{ typeOptions[scope.row.payType] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="discount" label="是否折扣">
        <template slot-scope="scope">
          <span>{{scope.row.discount===1?'是':'否'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog  :close-on-click-modal="false" :title="`${ruleForm.id?'编辑':'添加'}商品`" :visible.sync="addVisible" width="700px" top="3vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="ruleFormBox"
      >
        <el-form-item label="商品名称" prop="productName" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.productName"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品图标" prop="productUrl" :rules="rules.typeIcon">
          <uploadImg @change="uploadImgChange" :imageFile="[{url:ruleForm.productUrl}]"></uploadImg>
        </el-form-item>
        <el-form-item label="支付类型" prop="payType" :rules="rules.changeRule">
          <el-radio-group v-model="ruleForm.payType">
            <el-radio :label="Number(key)" v-for="(item,key) in typeOptions" :key="key">{{item}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="金币数量" prop="goldCoinsNum" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.goldCoinsNum"
            type="number"
            >
            <template slot="append"  v-if="ruleForm.payType!=4">Talk</template>
            <template slot="append"  v-else>ETH</template>
          </el-input>
        </el-form-item>
        <el-form-item label="商品价格" prop="productPrice" :rules="rules.priceCheck" v-if="ruleForm.payType!=4&&ruleForm.payType!=3">
          <el-input
            placeholder="请输入内容"
            type="number"
            v-model.number="ruleForm.productPrice"
          >
            <template slot="append">{{ruleForm.payType===3?'₹':'$'}}</template>
          </el-input>
        </el-form-item>
        <div v-if="ruleForm.payType==3" class="payChannelPriceList">
          <div v-for="(item,index) in ruleForm.payChannelPriceList" :key="index" class="payChannelItem">
            <div class="delBtn" @click="delPayChannel(index)" v-if="index>0">
              <el-button type="danger" icon="el-icon-delete" circle></el-button>
            </div>
            <!-- <el-form-item label="渠道图标" :prop="'payChannelPriceList.' + index + '.channelIcon'" :rules="rules.typeIcon">
              <uploadImg @change="val=>channelIconChange(val,index)" :imageFile="[{url:item.channelIcon}]"></uploadImg>
            </el-form-item> -->
            <el-form-item label="渠道" :prop="'payChannelPriceList.' + index + '.channel'" :rules="rules.changeRule">
              <el-select v-model="item.channel" placeholder="请选择">
                <el-option :label="item.channelName" :value="JSON.stringify(item)" v-for="(item,index) in channelOptions" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="货币符号" :prop="'payChannelPriceList.' + index + '.currency'" :rules="rules.blurRule">
              <el-input
                placeholder="请输入内容"
                v-model.number="item.currency"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="商品价格" :prop="'payChannelPriceList.' + index + '.price'" :rules="rules.priceCheck">
              <el-input
                placeholder="请输入内容"
                type="number"
                v-model.number="item.price"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="比率" :prop="'payChannelPriceList.' + index + '.rate'" :rules="rules.blurRule">
              <el-input
                placeholder="请输入内容"
                type="number"
                v-model.number="item.rate"
              >
              </el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="" v-if="ruleForm.payType==3">
            <el-button
            size="medium"
            type="warning"
            @click="addPayChannel"
            >添加支付渠道</el-button>
        </el-form-item>
        <el-form-item label="服务商商品id" prop="productId" :rules="rules.blurRule" v-if="ruleForm.payType==1||ruleForm.payType==2||ruleForm.payType==5">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.productId"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否折扣" prop="discount" :rules="rules.changeRule">
          <el-radio-group v-model="ruleForm.discount">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    var priceCheck = (rule, value, callback) => {
      if (value==='') {
        return callback(new Error('输入不能为空'));
      } else if (this.ruleForm.payType==3&&value<100) {
        return callback(new Error('价格不能小于100'));
      } else {
        return callback()
      }
    }
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {},
      searchForm: {
        name:null,
        sortOrder:null,
        sortField:null,
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      dialogVisible: false,
      typeOptions: {
          '1':'apple',
          '2':'pro-google',
          '3':'第三方支付',
          '4':'ETH',
          '5':'lite-google'
      },
      channelOptions:[
        {channel:'meta_go',channelName:'meta-go'},
        {channel:'one_pay',channelName:'one-pay'}
      ],
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
        priceCheck: [{ validator: priceCheck, trigger: "blur" }],
      },
    };
  },
  components:{uploadImg,pagination},
  created() {
    this.getTableList();
  },
  methods: {
    addPayChannel(){
      this.ruleForm.payChannelPriceList.push({rate:1})
    },
    delPayChannel(index){
      this.ruleForm.payChannelPriceList.splice(index,1)
    },
    addFunc(){
      this.ruleForm = {payChannelPriceList:[{rate:1}]}
      this.addVisible = true;
    },
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.productUrl=val[0].url
      }
    },
    channelIconChange(val,index){
      if(val&&val.length>0) {
        this.ruleForm.payChannelPriceList[index].channelIcon=val[0].url
      }
    },
    toEdit(data) {
      this.ruleForm = JSON.parse(JSON.stringify(data));
      if(this.ruleForm.payType==4) {
        this.ruleForm.goldCoinsNum = this.ruleForm.goldCoinsNum/10000
      } else if (this.ruleForm.payType==3){
        if(this.ruleForm.payChannelPriceList) {
          this.ruleForm.payChannelPriceList =this.ruleForm.payChannelPriceList.map(x=>{
            let channal = {channel:x.channel,channelName:x.channelName}
            x.channel=JSON.stringify(channal)
            return x;
          })
        } else {
          this.$set(this.ruleForm,'payChannelPriceList',[{rate:1}])
        }
      }
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
          if(this.ruleForm.payType==4) {
            ruleForm.goldCoinsNum = this.ruleForm.goldCoinsNum*10000
          } else if(this.ruleForm.payType==3) {
            ruleForm.payChannelPriceList = ruleForm.payChannelPriceList.map(x=>{
              let channel = JSON.parse(x.channel)
              return {...x,...channel}  
            })
          }
          let res = await this.$http.payProductSave({...ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.payProductList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    changeSort(val) {
      console.log(val)
      if (val.order) {
        this.searchForm.sortOrder = val.order === "ascending" ? 'ascend' : 'descend';
        this.searchForm.sortField = val.prop;
      } else {
        this.searchForm.sortOrder = "";
        this.searchForm.sortField = "";
      }
      this.getTableList(true);
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.payProductDel({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.payChannelItem {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #f5f7fa;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.delBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index:1;
}
.priceItem{
  display:flex;
  align-items: center;
}
</style>
